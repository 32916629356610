<template>
  <div id="lom-list">
    <v-container class="py-0">
      <v-speed-dial
        v-model="fab"
        :top="top"
        :bottom="bottom"
        :right="right"
        :left="left"
        :direction="direction"
        :open-on-hover="hover"
        :transition="transition"
        :fixed="true"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="blue darken-2" dark fab>
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-account-circle </v-icon>
          </v-btn>
        </template>
        <!-- <v-btn fab dark small color="green">
          <v-icon>mdi-pencil</v-icon>
        </v-btn> -->
        <v-btn fab dark small color="indigo" @click.prevent="addRecord">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <!-- <v-btn fab dark small color="red">
          <v-icon>mdi-delete</v-icon>
        </v-btn> -->
      </v-speed-dial>
    </v-container>
    <v-card>
      <v-card-title class="text-h5"> <h2>Users List</h2> </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>Search the loms by the filter</h4></v-card-subtitle
      >

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container>
          <v-form ref="form2" v-model="valid1" lazy-validation>
            <v-row wrap>
              <!-- <v-col cols="12" md="3">
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="JciYearCodeOptions"
                  :rules="JciYearCodeRules"
                  :loading="JciYearCodeOptionsLoading"
                  v-model="JciYearCode"
                  label="Year"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col> -->
              <v-col cols="12" md="3">
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="RoleCodeOptions"
                  :rules="RoleCodeRules"
                  :loading="RoleCodeOptionsLoading"
                  v-model="RoleCode"
                  label="Role"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="searchForm"
                  :loading="SearchFlag"
                  color="#8950FC"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="
                    btn btn-primary
                    font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                    white--text
                  "
                >
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container class="py-0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3>{{ rows.length }} records found.</h3>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search records here"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                v-model="selected"
                :loading="SearchFlag"
                loading-text="Loading... Please wait"
                :headers="tableColumns1"
                :items="rows"
                :search="search"
                :items-per-page="tableOptions1.ItemsPerPage"
                :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag"
                :show-select="tableOptions1.ShowSelectFlag"
                :footer-props="tableOptions1.FooterProps"
              >
                <template v-slot:item.ActiveStatusTxt="{ item }">
                  <v-chip
                    :color="getActiveStatusColor(item.ActiveStatusTxt)"
                    draggable
                    dark
                    >{{ item.ActiveStatusTxt }}</v-chip
                  >
                </template>
                <template v-slot:item.ProfilePic="{ item }">
                  <img width="100" height="100" :src="item.ProfilePic" />
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="primary"
                        @click="previewData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-clipboard-text-search
                      </v-icon>
                    </template>
                    <span> Preview </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="primary"
                        @click="previewData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-document-edit
                      </v-icon>
                    </template>
                    <span> Edit </span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
  mixins: [common],
  components: {},
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      rows: [],
      tableColumns1: [],
      tableOptions1: {},
      selected: [],
      search: "",
      CurrentYearId: "",

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      zone: {},
      lom: {},

      RoleCodeRules: [(v) => !!v || "Zone is required"],
      RoleCode: "",
      RoleCodeOptions: [],
      RoleCodeOptionsLoading: false,
    };
  },
  mounted() {
    /*
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Form Inputs & Control", route: "autocompletes" },
      { title: "Fileinptus" },
    ]);
    */
  },
  watch: {
    SearchId: function () {
      console.log("watch SearchId");
      this.rows = [];
    },
    RoleCode: function () {
      console.log("watch RoleCode");
    },
    RoleCodeOptions: function () {
      console.log("watch RoleCodeOptions");
      // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      this.LoadingFlag = false;
      this.RoleCodeOptionsLoading = false;
    },
  },
  methods: {
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    addRecord() {
      console.log("addRecord called");
      var url = "/lom/create";
      var params = {};
      this.pageRedirect(params, url);
    },
    resetForm() {
      this.search = "";
      this.rows = [];
      this.selected = [];
      this.$refs.form3.reset();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    previewData(tr) {
      console.log("previewData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var url = "/lom/details";
        var LomId = tr.LomId;
        console.log("LomId=" + LomId);
        var params = {
          id: LomId,
        };
        this.pageRedirect(params, url);
      } else {
        var message = "Kindly select one lom to preview";
        this.toast("error", message);
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      // this.JciYearCodeOptionsLoading = true;
      // this.getYearCodeOptions("JciYearCode", "JciYearCodeOptions");
      this.RoleCodeOptionsLoading = true;
      this.getRoleCodeOptions("RoleCode", "RoleCodeOptions");
    },
    getRoleCodeOptions(source, destination) {
      console.log("getRoleCodeOptions called");
      // this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      if (source != "" && destination != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/roles/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    searchForm() {
      console.log("searchForm is called");

      var validate1 = this.$refs.form2.validate();

      if (validate1) {
        this.SearchFlag = true;
        this.rows = [];

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/users/lists";
        var upload = {
          UserInterface: 1,
          RoleCode: this.RoleCode
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            thisIns.tableColumns1 = records.TableHeader;
            thisIns.tableOptions1 = records.TableOptions;

            if (flag == 1) {
              thisIns.toast("success", output);
              thisIns.rows = records.TableData;
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
  },
  mounted() {
    // this.searchForm();
  },
  beforeMount() {
    var CurrentYearId = this.$session.get("CurrentYearId");
    CurrentYearId = 1;
    CurrentYearId = CurrentYearId == (null || undefined) ? 0 : CurrentYearId;
    this.CurrentYearId = CurrentYearId;
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#lom-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
}
</style>